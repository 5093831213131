.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.app_header {
  background-color: rgb(28, 37, 54);
}

.error-class-message {
  color: #d32f2f;
  /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 4px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}

.clickableText:hover {
  cursor: pointer;
}

.clickableText {
  text-decoration: underline;
}

.red {
  color: #d32f2f;
}

.red1 {
  color: #ff0000;
}

.yellow {
  color: #d29d4a;
}

.yellow2 {
  color: #ff8c00;
}

.yellow1 {
  color: #f4c430;
}

.green {
  color: #4caf50;
}

.custom-mui-table table tbody tr:first-child {
  position: sticky;
  z-index: 10;
  overflow: hidden;
  top: 46px;
  box-shadow: 20px 20px 20px #0000;
}

.custom-mui-table-theading table thead tr:first-child {
  position: sticky;
  z-index: 10;
  overflow: hidden;
  top: 0px;
  box-shadow: 20px 20px 20px #0000;
}
.custom-mui-table-theading table thead tr:nth-child(2) {
  position: sticky;
  z-index: 10;
  overflow: hidden;
  top: 38px !important;
  box-shadow: 20px 20px 20px #0000;
}

.remove-add-more-item {
  display: flex;
  justify-content: flex-end;
}

.add-more-container-a {
  margin-top: 20px;
}
