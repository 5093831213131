.login-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.left-login-container {
  flex: 1;
  overflow: hidden;
  box-shadow: 2px 2px 10px 2px black;
}

.left-login-container-img {
  height: 100%;
  width: 100%;
}

.login-form-container {
  width: 25rem;
}

.right-login-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  align-items: center;
}

.login-form-forgot {
  cursor: pointer;
  color: red;
  display: flex;
  justify-content: center;
  font-family: "Poppins";
}
