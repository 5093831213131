.heading-reset-pwd-container {
  padding-left: 30px;
  padding-right: 30px;
}

.back-to-login-hyperlink {
  margin: 0px;
  font-size: 0.875rem;
  line-height: 1.57;
  /* font-family: "Public Sans", sans-serif; */
  font-weight: 400;
  color: rgb(24, 144, 255);
  text-decoration: none;
}
