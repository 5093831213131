.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.warning-container-text {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}
