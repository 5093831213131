.home-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.home-hero-container-top {
  position: sticky;
  top: 0px;
  z-index: 1000;
}

.home-hero-container-bottom {
  flex: 1;
  display: flex;
}

.home-hero-container-bottom-right {
  flex: 1;
  background-color: #f1f1f18c;
  width: calc(100% - 320px);
}

/* .home-hero-container-right {
  background-color: #f1f1f18c;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
}

.home-hero-container-right-top {
  width: 100%;
  position: sticky;
  top: 0px;
  z-index: 100;
  backdrop-filter: blur(0.1px);
}

.home-hero-container-right-bottom {
  width: 100%;
} */

.welcome-msg-container {
  margin-left: 15px;
  width: 90%;
}

.table-stats-container {
  width: 90%;
  box-shadow: 2px 2px 100px 2px black;
  border-radius: 10px;
}
.footer {
  position: sticky;
  bottom: 0px;
  z-index: 100;
  backdrop-filter: blur(0.1px);
  width: 100vw;
}

@media only screen and (max-width: 900px) {
  .home-hero-container-right-top {
    backdrop-filter: blur(8px) !important;
  }
}

/* .MuiTableContainer-root {
  /* max-height: calc(80vh - 100px); */
/* }  */
